<template>
  <v-card color="indigo lighten-5">
    <v-data-table
      :headers="headers"
      :items="getNotificationSettingsWithExpiration"
      :loading="loading"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="getNotificationSettingsCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      class="elevation-1"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th colspan="10" class="text-center" style="border-right: thin solid rgba(0, 0, 0, 0.12)">Подія</th>
            <th colspan="8" class="text-center">Місце</th>
          </tr>
        </thead>
      </template>
      <template v-slot:header.updated_at="{ header }">
        <TableTitleDateFilter
          :title-text="header.text"
          @select="(selected) => (selectedDates = selected)"
          @ok="applyDateFilter('updated_at', selectedDates)"
        />
      </template>
      <template v-slot:header.device_number="{ header }">
        <div class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ header.text }}</div>
            </template>
            <span>Номер автомату</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:header.event="{ header }">
        <TableTitleFilter
          :values="selectedEvents"
          :options="pushNotificationEventsDictionary"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (selectedEvents = selected)"
          @ok="applyFilter('event', selectedEvents)"
        />
      </template>
      <template v-slot:header.initiator="{ header }">
        <TableTitleFilter
          :values="selectedInitiators"
          :options="initiators"
          :options-meta="initiatorsMeta"
          :options-params="initiatorsOptionsData"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedInitiators = selected)"
          @ok="applyFilter('initiator.id', selectedInitiators)"
        >
          <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.type="{ header }">
        <TableTitleFilter
          :values="selectedTypes"
          :options="pushNotificationTypesDictionary"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (selectedTypes = selected)"
          @ok="applyFilter('type', selectedTypes)"
        />
      </template>
      <template v-slot:header.receiver_type="{ header }">
        <TableTitleFilter
          :values="selectedReceiversTypes"
          :options="pushNotificationRecipientsDictionary"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (selectedReceiversTypes = selected)"
          @ok="applyFilter('receiverType', selectedReceiversTypes)"
        />
      </template>

      <template v-slot:header.companies="{ header }">
        <TableTitleFilter
          :values="selectedCompanies"
          :options="companies"
          :options-meta="companiesMeta"
          :options-params="companiesOptionsData"
          :title-text="header.text"
          item-value="id"
          item-text="name"
          @select="(selected) => (selectedCompanies = selected)"
          @ok="applyFilter('companies.id', selectedCompanies)"
        />
      </template>
      <template v-slot:header.balance_holders="{ header }">
        <TableTitleFilter
          :options="getBalanceHolderFilter"
          :title-text="header.text"
          @select="setSelectedBalanceHolders"
          @ok="applyFilter('balanceHolders.id', selectedBalanceHolders)"
        />
      </template>
      <template v-slot:header.service_points="{ header }">
        <TableTitleFilter
          :options="getServicePointFilter"
          :title-text="header.text"
          @select="setSelectedServicePoints"
          @ok="applyFilter('servicePoints.id', selectedServicePoints)"
        />
      </template>
      <template v-slot:header.device_type="{ header }">
        <TableTitleFilter
          :options="connectedDevicesTypesDictionary"
          :title-text="header.text"
          item-value="name"
          item-text="description"
          @select="setSelectedDevicesType"
          @ok="applyFilter('devicesTypes.id', selectedDevicesTypes)"
        />
      </template>
      <template v-slot:header.devices="{ header }">
        <TableTitleFilter
          :options="getDeviceFilter"
          :title-text="header.text"
          @select="setSelectedDevices"
          @ok="applyFilter('devices.id', selectedDevices)"
        >
          <template v-slot:item="{ item }">{{ item.label }} ({{ item.type }}{{ item.value }})</template>
        </TableTitleFilter>
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at ? item.updated_at : item.created_at | getShortDate }}
        <sup>{{ item.updated_at ? item.updated_at : item.created_at | getShortTime }}</sup>
      </template>
      <template v-slot:item.start_at="{ item }">
        {{ item.start_at | getShortDate }}
        <sup>{{ item.start_at | getShortTime }}</sup>
      </template>
      <template v-slot:item.expired_at="{ item }">
        <span :class="{ 'red--text text--lighten-1': item.isExpired }">
          {{ item.expired_at | getShortDate }}
          <sup>{{ item.expired_at | getShortTime }}</sup>
        </span>
      </template>
      <template v-slot:item.event="{ item }"> {{ getItemTitle(pushNotificationEventsDictionary, item.event) }}</template>
      <template v-slot:item.initiator="{ item }"> {{ item.initiator.first_name }} {{ item.initiator.last_name }} </template>
      <template v-slot:item.type="{ item }"> {{ getItemTitle(pushNotificationTypesDictionary, item.type) }}</template>
      <template v-slot:item.contentTitle="{ item }"> {{ item.content | getCurrentLocaleData($currentLocale, 'title') }}</template>
      <template v-slot:item.contentBody="{ item }"> {{ item.content | getCurrentLocaleData($currentLocale, 'body') }} </template>
      <template v-slot:item.receiver_type="{ item }">
        {{ getItemTitle(pushNotificationRecipientsDictionary, item.receiver_type) }}
      </template>
      <template v-slot:item.companies="{ item }">
        <router-link
          v-for="(company, index) in item.companies"
          :key="company.id"
          :to="{ name: 'CompanyCard', params: { id: company.id } }"
          class="text-decoration-none"
        >
          {{ company.name }}
          <span v-show="index !== item.companies.length - 1">,</span>
        </router-link>
      </template>
      <template v-slot:item.balance_holders="{ item }">
        <router-link
          v-for="(partner, index) in item.balance_holders"
          :key="partner.id"
          :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: partner.id } }"
          class="text-decoration-none"
        >
          {{ partner.name }}
          <span v-show="index !== item.balance_holders.length - 1">,</span>
        </router-link>
      </template>
      <template v-slot:item.service_points="{ item }">
        <router-link
          v-for="(sp, index) in item.service_points"
          :key="sp.id"
          :to="{
            name: 'ServicePointCard',
            params: { id: currentCompanyId, servicePointId: sp.id },
          }"
          class="text-decoration-none"
        >
          {{ sp.name }}<span v-show="index !== item.service_points.length - 1">,</span>
        </router-link>
      </template>
      <template v-slot:item.devices="{ item }">
        <router-link
          v-for="(device, index) in item.devices"
          :key="device.id"
          :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: device.id } }"
          class="text-decoration-none"
        >
          {{ getItemTitle(machinesTypesDictionary, device.type) }}{{ device.id }}
          <span v-show="index !== item.devices.length - 1">,</span>
        </router-link>
      </template>
      <template v-slot:item.is_active="{ item }">
        <CircleIndicator :is-on="item.is_active" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="navigateToEditSettings(item.id)" class="mr-2" small v-bind="attrs" v-on="on">
              mdi-pencil-outline
            </v-icon>
          </template>
          <span>Редагувати</span>
        </v-tooltip>
        <v-tooltip bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" small @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
          </template>
          <span>Видалити</span>
        </v-tooltip>
        <v-tooltip bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="navigateToPushSettingCard(item)" small v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
          </template>
          <span>Переглянути</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="resetData"> Оновити </v-btn>
      </template>
    </v-data-table>

    <v-card class="mt-5" flat>
      <PushNotificationsLogs :preset-is-one-time="[0]" />
    </v-card>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Правило буде видалено!</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
          <v-btn text @click="dialogDelete = false">Нi</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import resetTableData from '@/mixins/resetTableData'
import CircleIndicator from '@/components/common/CircleIndicator'
import ConvertDate from '@/mixins/convertDate'
import UseCurrentLocaleData from '@/mixins/useCurrentLocaleData'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter.vue'
import manageFiltersOptions from '@/mixins/manageFiltersOptions'
import sortUtils from '@/mixins/sortUtils'
import convertToDictionary from '@/mixins/convertToDictionary'
import PushNotificationsLogs from '@/components/support/push_notification/PushNotificationsLogs.vue'
import filterUtils from '@/mixins/filterUtils'
import { isEqual } from 'lodash'
import TableTitleDateFilter from '@/components/common/filters/TableTitleFilter/TableTitleDateFilter.vue'

export default {
  name: 'AutoNotifications',

  components: { TableTitleDateFilter, PushNotificationsLogs, TableTitleFilter, CircleIndicator },

  mixins: [resetTableData, ConvertDate, manageFiltersOptions, sortUtils, convertToDictionary, filterUtils, UseCurrentLocaleData],

  data: () => ({
    loading: false,
    sortBy: 'created_at',
    sortDesc: true,
    options: {},
    selectedEvents: [],
    selectedTypes: [],
    selectedReceiversTypes: [],
    selectedBalanceHolders: [],
    payload: null,
    dialogDelete: false,
    clickedSettingId: null,
    headers: [
      {
        text: 'ID',
        value: 'id',
        sortable: true,
      },
      {
        text: 'Створено/змінено',
        value: 'updated_at',
        sortable: true,
        width: 105,
      },
      {
        text: 'Термін дії з',
        value: 'start_at',
        sortable: true,
      },
      {
        text: 'Термін дії до',
        value: 'expired_at',
        sortable: true,
      },
      {
        text: 'Подія',
        value: 'event',
        sortable: false,
      },
      {
        text: 'Ініціатор',
        value: 'initiator',
        sortable: false,
      },
      {
        text: 'Тип',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Назва',
        value: 'contentTitle',
        sortable: false,
      },
      {
        text: 'Текст',
        value: 'contentBody',
        sortable: false,
      },
      {
        text: 'Включено',
        value: 'is_active',
        sortable: false,
      },
      {
        text: 'Отримувач',
        value: 'receiver_type',
        sortable: false,
        divider: true,
      },
      {
        text: 'Компанія',
        value: 'companies',
        sortable: false,
      },
      {
        text: 'Орендодавець',
        value: 'balance_holders',
        sortable: false,
      },
      {
        text: 'Торгівельна точка',
        value: 'service_points',
        sortable: false,
      },
      {
        text: 'Тип A',
        value: 'device_type',
        sortable: false,
      },
      {
        text: 'Автомат',
        value: 'devices',
        sortable: false,
      },
      { text: '', value: 'actions', sortable: false, width: '100px', align: 'left' },
    ],
  }),

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('pushNotification', ['notificationSettingsMeta']),
    ...mapState('dictionaries', [
      'pushNotificationEventsDictionary',
      'pushNotificationTypesDictionary',
      'pushNotificationRecipientsDictionary',
      'machinesTypesDictionary',
      'connectedDevicesTypesDictionary',
    ]),
    ...mapState('companies', ['companies', 'companiesMeta']),
    ...mapState('users', ['initiators', 'initiatorsMeta']),
    ...mapGetters('pushNotification', ['getNotificationSettingsCount', 'getNotificationSettingsWithExpiration']),
    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter', 'getDeviceFilter']),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  watch: {
    options: {
      handler(val, old) {
        if (!isEqual(val, old)) {
          this.paginateTo()
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('pushNotification', ['loadNotificationSettings', 'deletePushNotification']),
    ...mapActions('companies', ['getCompanies', 'loadFilter']),

    async initialize() {
      await this.loadFilter(this.currentCompanyId)
    },

    async paginateTo() {
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        ...this.filterObject,
      }
      this.loading = true
      try {
        await this.loadNotificationSettings(this.payload)
      } finally {
        this.loading = false
      }
    },

    navigateToEditSettings(id) {
      this.$router.push({ name: 'PushNotificationSettings', params: { notificationId: id } })
    },

    navigateToPushSettingCard(item) {
      const clickedItemId = item.id
      this.$router.push({
        name: 'PushNotificationSettingCard',
        params: { id: this.currentCompanyId.toString(), notificationId: clickedItemId.toString() },
      })
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.clickedSettingId = item.id
    },

    async deleteItemConfirm() {
      const payload = {
        id: this.clickedSettingId,
      }
      await this.deletePushNotification(payload)
      this.dialogDelete = false
      await this.paginateTo()
    },
  },
}
</script>
